.root {
    /*background-color: #f4f6f8;*/
    display: flex;
    height: 100%;
    overflow: hidden;
    width: 100%;
    background: '#F8FAFF';
}

.wrapper {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    padding-top: 54px;
}

.contentContainer {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
}

.content {
    flex: 1 1 auto;
    height: 100%;
    overflow: auto;
}
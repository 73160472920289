.white_input input:-webkit-autofill {
    color: #FFFFFF !important;
}

.white_input input:-webkit-autofill,
.white_input input:-webkit-autofill:hover,
.white_input input:-webkit-autofill:focus,
.white_input input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #003594 inset !important;
    -webkit-text-fill-color: #FFFFFF !important;
}

.skeleton-loading {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
